<style lang="scss">
#debug {
  display: none;
}
#frontend {
  .coporate-social-responsibility-all {
    .full-width-image {
      width: 100%;
      height: 730px;
      background-size: cover;
      background-position: top center;
      border-bottom: 10px solid $blue-60per;
      @media (min-width: 2000px) {
        height: 800px;
      }
      @media (min-width: 3000px) {
        height: 900px;
      }
      @media (max-width: 1600px) {
        height: 600px;
      }
      @media (max-width: 1200px) {
        height: 500px;
      }
      @media (max-width: $width-lg) {
        height: 400px;
      }
      @media (max-width: $width-md) {
        height: 350px;
      }
    }
    .img-logo {
      max-width: 300px;
      margin: 150px auto;
      display: block;
      @media (max-width: $width-lg) {
        margin: 100px auto;
        max-width: 240px;
      }
    }

    .border-boxen {
      //border:1px solid red;
      .container {
        //border:1px solid red;
      }
      .col-12 {
        position: relative;
        //border:1px solid blue;
        .inner-box-border {
          border: 10px solid $blue-60per;
          padding: 0px 0;
          width: 140%;
          position: relative;
          @media (max-width: $width-md) {
            width: 100%;
          }
          .line-left {
            width: 100%;
            height: 10px;
            background: $blue-60per;
            position: absolute;
            left: -100%;
            top: 50px;
            @media (max-width: 600px) {
              display: none;
            }
          }
          .txt-box {
            //border:1px solid green;
            padding-top: 80px;
            padding-bottom: 80px;
            max-width: 60%;
            @media (max-width: $width-md) {
              max-width: 100%;
              //border:1px solid red;
            }
            h3 {
              text-transform: none;
              margin-bottom: 20px;
              font-family: 'Helvetica Neue LT W05_75 Bold';
            }
            p {
              margin-bottom: 0;
            }
            .font-itc {
              margin-top: 30px;
              font-family: 'American Typewriter ITC W04 Md';
              .breaker {
                display: block;
                height: 0;
              }
            }
          }
        }
        .inner-box-left {
          margin-top: 100px;
          //transform: translateX(-670px);
          left: -40%;
          @media (max-width: 1300px) {
          }
          @media (max-width: $width-xl) {
          }
          @media (max-width: 800px) {
          }
          @media (max-width: $width-md) {
            left: 0;
          }

          .txt-box {
            margin-left: auto;
            padding-left: 40px;
            padding-right: 120px;
            @media (max-width: $width-md) {
              padding-right: 40px;
              margin-left: 0;
            }
          }
          .btn-box {
            .btn-inner {
              max-width: 430px;
              margin-left: auto;
            }
          }
        }
        .inner-box-right {
          //transform: translateX(670px) translateY(-200px);
          right: -10%;

          @media (max-width: 1300px) {
          }
          @media (max-width: $width-xl) {
          }
          @media (max-width: 800px) {
          }
          @media (max-width: $width-md) {
            right: 0;
          }
          .txt-box {
            padding-left: 100px;
            padding-right: 40px;
            @media (max-width: $width-md) {
              padding-left: 40px;
            }
          }
        }
      }
    }
  }
}
</style>

<template>
  <SocialMediaIconBox />
  <div class="coporate-social-responsibility-all">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="headline-box-std">
            <div class="headline-inner">
              <div class="breadcrumb">
                <span>{{ $t('General.ueber-uns') }}</span>
              </div>
              <h1 v-html="$t('corporate-social-responsibility.title')"></h1>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="full-width-image" :style="{ 'background-image': `url(${require('@/assets/img/ueber-uns/coporate-social-responsibility-v2.jpg')})` }"></div>
    <div class="container">
      <div class="row">
        <div class="col-12">
          <img src="@/assets/img/ueber-uns/corporate-social-responsibility-logo.svg" alt="Corporate Social Responsibility" class="img-fluid img-logo" />
        </div>
      </div>
    </div>
    <div class="border-boxen">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="inner-box-border inner-box-right" v-inview="['animation-delay-0-5s', 'move-in-from-right']">
              <div class="line-left"></div>
              <div class="txt-box">
                <h3>{{ $t('corporate-social-responsibility.angewandte-ethik-title') }}</h3>
                <p>{{ $t('corporate-social-responsibility.angewandte-ethik-text-v1') }}</p>
                <p class="font-itc" v-html="$t('corporate-social-responsibility.angewandte-ethik-text-v2')"></p>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="inner-box-border inner-box-left" v-inview="['animation-delay-0-5s', 'move-in-from-left']">
              <div class="txt-box">
                <h3>{{ $t('corporate-social-responsibility.styria-care-title') }}</h3>
                <p>{{ $t('corporate-social-responsibility.styria-care-text-v1') }}</p>
                <p>{{ $t('corporate-social-responsibility.styria-care-text-v2') }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container margin-t-l">
        <div class="row justify-content-center">
          <div class="col-12">
            <div class="headline-box-std">
              <div class="headline-inner">
                <h1>News</h1>
              </div>
            </div>
          </div>
          <div class="col-lg-8 text-center margin-b-l">
            <p>Green Stories ohne Green Washing: Seit Gründung der STYRIA stehen Nachhaltigkeitsthemen fix auf ihrer Agenda. So auch in der aktuellen Strategie #styria_ff. In unserem Prozess zur Corporate Social Responsibility finden wir Antworten auf die für uns wesentlichsten Fragen. <br />Mit anderen Worten: #styria_response.</p>
          </div>
        </div>
        <div class="row justify-content-center align-self-center">
          <EntryIndex category="5" showerror paged />
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import SocialMediaIconBox from '../../../components/Frontend/SocialMediaIconBox.vue';
import Footer from '../../../components/Frontend/Footer.vue';
import { mdiChevronUp } from '@mdi/js';
import EntryIndex from '../../../components/Frontend/EntryIndex.vue';

export default defineComponent({
  meta: {
    de: {
      title: 'Corporate Social Responsibility | Styria Media Group',
      keywords: ['csr', 'coporate social responsibility', 'ethik', 'styria ethics', 'joanneum', 'styria care', 'palliative care'],
      description: 'Qualifizierte Information und öffentliche Meinungsbildung in Themen und Fragen der Ethik liegen der Styria Media Group besonders am Herzen.',
      image: require('@/assets/img/og/ueber-uns.png'),
    },
    en: {
      title: 'Corporate Social Responsibility | Styria Media Group',
      keywords: ['csr', 'coporate social responsibility', 'ethik', 'styria ethics', 'joanneum', 'styria care', 'palliative care'],
      description: 'Qualified information and public opinion-forming on topics and questions of ethics are particularly important to the Styria Media Group.',
      image: require('@/assets/img/og/ueber-uns.png'),
    },
  },
  components: {
    SocialMediaIconBox,
    Footer,
    EntryIndex,
  },
  setup() {
    return {
      mdiChevronUp,
    };
  },
});
</script>
